import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  mixins: [mixins],
  name: 'gps',
  data() {
    return {
      areas: [],
      //区域
      areaProps: {
        value: 'id',
        label: 'name',
        emitPath: false
      },
      pathParam: [],
      //线路
      edit: false,
      residenceOptions: [],
      form: {
        id: null,
        areaId: '',
        paths: [] // 围栏点坐标；
      },

      formLabelWidth: '150px',
      listLoading: false,
      tableData: [],
      editVisible: false,
      AMap: null,
      map: null,
      centerLngLat: [104.07318, 30.662818],
      // 默认当前坐标为成都市坐标
      initPath: [],
      path: [],
      // 当前绘制的多边形经纬度数组
      polygonItem: [],
      // 地图上绘制的所有多边形对象
      polyEditors: [] // 所有编辑对象数组
    };
  },

  methods: {},
  mounted() {
    AMapLoader.load({
      "key": this.$amapKey,
      // 申请好的Web端开发者Key，首次调用 load 时必填
      "version": "2.0",
      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      "plugins": ['AMap.Scale', 'AMap.PolyEditor', 'AMap.MouseTool', 'AMap.Polygon', 'AMap.Polyline'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then(AMap => {
      this.map = new AMap.Map("amapContainer", {
        resizeEnable: true
      });
      if (this.$route.query) {
        this.$api.business.rideRecord.getGPS(this.$route.query).then(res => {
          if (res.code === 200) {
            res.data.forEach(v => {
              if (v.longitude > 0) this.pathParam.push([v.longitude, v.latitude]);
            });
            var oldLine = new AMap.Polyline({
              path: this.pathParam,
              strokeWeight: 8,
              strokeOpacity: 1,
              strokeColor: 'red',
              showDir: true
            });
            this.map.add(oldLine);
            this.map.setFitView();
          }
        }).finally(() => {});
      }
    }).catch(e => {
      console.log('高德初始化失败', e);
    });
  }
};